import { FC, memo } from 'react';

import ProCard from '@ant-design/pro-card';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Flex, Space, Tooltip, Typography } from 'antd';

// Constants
import { ROUTES } from '../../../../../constants/routes-constants';

// Interfaces
import type { Evento } from '../../../../../interfaces/evento';

interface EventHeaderProps {
  evento: Evento;
  action: string;
  extra?: React.ReactNode;
}

export const EventHeader: FC<EventHeaderProps> = memo((props) => {
  const { evento } = props;
  const navigate = useNavigate();

  return (
    <ProCard bordered>
      <Flex justify="space-between">
        <Space>
          <Tooltip title="Volver">
            <Button
              onClick={() =>
                navigate(ROUTES.EVENT_DETAILS_ROUTE.replace(':id', evento.id.toString()))
              }
              icon={<LeftOutlined />}
            ></Button>
          </Tooltip>

          <Typography.Title level={3} style={{ margin: 0 }}>
            {props.action} {evento.nombre}
          </Typography.Title>
        </Space>

        {props.extra}
      </Flex>
    </ProCard>
  );
});

EventHeader.displayName = 'EventHeader';

export default EventHeader;
