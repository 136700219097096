import { Modal, ModalProps, Result, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import AuthService from '../../../../services/auth.service';

enum TYPES {
  NOT_FOUND = 'NOT_FOUND',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  EMPTY = 'EMPTY'
}

const MESSAGES: Record<TYPES, string> = {
  NOT_FOUND: 'El token de validación de email no pudo ser encontrado',
  EXPIRED: 'El token de validación de email ha expirado',
  ERROR: 'Hubo un error inesperado al intentar validar el email.',
  SUCCESS: 'Email validado correctamente',
  EMPTY: ''
};

const SUBMESSAGE: Record<TYPES, string> = {
  NOT_FOUND: 'Por favor, intenta nuevamente',
  EXPIRED: 'Se le ha enviado un nuevo email de validación',
  ERROR: 'Por favor, intenta nuevamente',
  SUCCESS: 'Ahora puedes iniciar sesión',
  EMPTY: ''
};

export const ValidateEmailModal: FC<ModalProps> = (props) => {
  const [validating, setValidating] = useState(true);
  const [type, setType] = useState<TYPES>(TYPES.EMPTY);

  const { open } = props;

  useEffect(() => {
    if (open) {
      const token = new URLSearchParams(window.location.search).get('token');

      if (!token) {
        setType(TYPES.NOT_FOUND);
      }

      AuthService.validateEmail(token as string)
        .then(() => {
          setType(TYPES.SUCCESS);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            setType(TYPES.EXPIRED);
          } else {
            setType(TYPES.ERROR);
          }
        })
        .finally(() => {
          setValidating(false);
        });
    }
    return () => {
      setType(TYPES.EMPTY);
      setValidating(true);
    };
  }, [open]);

  return (
    <div>
      <Modal {...props} centered footer={[]} closable={!validating}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 200
          }}
        >
          {validating ? (
            <Spin tip="Loading" size="large"></Spin>
          ) : (
            <Result
              title={MESSAGES[type]}
              subTitle={SUBMESSAGE[type]}
              status={type === 'SUCCESS' ? 'success' : 'error'}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
