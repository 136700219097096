import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import * as EventoService from '../../services/eventos.service';

// Utils
import { isActionableForUser } from '../../utils/validateRole';

// Constants
import { PERMISSIONS } from '../../constants/permissions';

// Interfaces
import type { RootState } from '../store';
import type { Evento, PublicEventRead } from '../../interfaces/evento';

export const fetchEvento = createAsyncThunk<Evento, number>(
  'evento/fetchEventoStatus',
  // @ts-ignore
  async (id, { rejectWithValue, getState }) => {
    const {
      auth: { user }
    } = getState() as RootState;

    const request =
      !user || !isActionableForUser(user, [PERMISSIONS.EVENTOS_READ])
        ? EventoService.getPublicEventById
        : EventoService.getEventoById;

    try {
      return request(id as number);
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.response?.status);
    }
  }
);

interface EventoState {
  loading: boolean;
  evento: Evento | PublicEventRead | null;
}

const initialState: EventoState = { loading: false, evento: null };

const EventoSlice = createSlice({
  name: 'Evento',
  initialState,
  reducers: {
    setEvento: (state, action) => {
      state.evento = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEvento.fulfilled, (state, action) => {
      state.evento = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchEvento.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchEvento.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const EventoActions = {
  ...EventoSlice.actions,
  fetchEvento
};

export default EventoSlice.reducer;
