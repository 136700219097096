import { memo, useEffect, useState } from 'react';

// Utils
import { useParams } from 'react-router-dom';
import { isActionableForUser } from '../../../../utils/validateRole';

// Components
import EventDetailsContainer from './container';
import Loader from '../../../../components/layout/loader';

// Redux
import { useAppSelector } from '../../../../store/store';

// Constants
import { PERMISSIONS } from '../../../../constants/permissions';

// Services
import EventoService from '../../../../services/eventos.service';
import { InscripcionService } from '../../../../services/inscripciones.service';

// Interfaces
import type { Evento } from '../../../../interfaces/evento';

export const EventDetailsPage = memo(() => {
  const params = useParams<{ id: string }>();
  const user = useAppSelector((state) => state.auth.user);

  const [loading, setLoading] = useState<boolean>(true);
  const [evento, setEvento] = useState<Evento | null>(null);
  const [isInscripted, setIsInscripted] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const request = !isActionableForUser(user!, [PERMISSIONS.EVENTOS_READ])
      ? EventoService.getPublicEventById
      : EventoService.getEventoById;

    request(Number(params.id))
      .then((response) => {
        setEvento(response as Evento);
      })
      .finally(() => setLoading(false));
  }, [params.id, user]);

  useEffect(() => {
    if (evento && user) {
      InscripcionService.isInscripted(evento.id).then((response) => {
        setIsInscripted(response.isInscripted);
      });
    }
  }, [evento, user]);

  if (loading) return <Loader />;

  return (
    <EventDetailsContainer evento={evento!} isInscripted={isInscripted} setEvento={setEvento} />
  );
});

EventDetailsPage.displayName = 'EventDetailsPage';

export default EventDetailsPage;
