import { FC, memo } from 'react';

import { Card } from 'antd';

// Components
import { EventButton } from './button';

// Utils
import { getEventoImagePath } from '../../../../../../utils/getImagePath';

// Interfaces
import type { Evento } from '../../../../../../interfaces/evento';
import EventCountDown from './countdown';

interface SubscribeCardProps {
  evento: Evento;
  isInscripted?: boolean;
}

export const SubscribeCard: FC<SubscribeCardProps> = memo((props) => {
  const { evento, isInscripted = false } = props;

  return (
    <Card
      cover={
        <div className="event-card-image">
          <img
            alt="imagen-del-evento"
            src={getEventoImagePath(evento, true)}
            style={{ height: 240, width: '100%', objectFit: 'cover' }}
          />
        </div>
      }
    >
      <div>
        <EventButton evento={evento} isInscripted={isInscripted} />
      </div>

      <div>
        <EventCountDown evento={evento} isInscripted={isInscripted} />
      </div>
    </Card>
  );
});

SubscribeCard.displayName = 'SubscribeCard';

export default SubscribeCard;
