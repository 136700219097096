const PERMISSIONS_LIST = [
  'ENTIDADES_CREATE',
  'ENTIDADES_DELETE',
  'ENTIDADES_LIST',
  'ENTIDADES_LOGO',
  'ENTIDADES_PRODUCTOS_DEMANDADOS',
  'ENTIDADES_PRODUCTOS_OFRECIDOS',
  'ENTIDADES_READ',
  'ENTIDADES_RUBROS',
  'ENTIDADES_SECTOR_ACTIVIDAD',
  'ENTIDADES_SELF_LOGO',
  'ENTIDADES_SELF_READ',
  'ENTIDADES_TIPO_ENTIDAD',
  'ENTIDADES_UPDATE',
  'ENTIDADES_USUARIO',
  'EVENTOS_CREATE',
  'EVENTOS_DELETE',
  'EVENTOS_LIST',
  'EVENTOS_LIST_INSCRIPCIONES',
  'EVENTOS_READ',
  'EVENTOS_SET_LOGO',
  'EVENTOS_UPDATE',
  'INSCRIPCIONES_CREATE',
  'INSCRIPCIONES_DELETE',
  'INSCRIPCIONES_LIST',
  'INSCRIPCIONES_READ',
  'INSCRIPCIONES_REUNIONES_DESEADAS',
  'INSCRIPCIONES_SELF_CREATE',
  'INSCRIPCIONES_SELF_LIST',
  'INSCRIPCIONES_SELF_REUNIONES_DESEADAS',
  'INSCRIPCIONES_UPDATE',
  'MUNICIPIOS_LIST',
  'MUNICIPIOS_READ',
  'PERMISSIONS_CREATE',
  'PERMISSIONS_DELETE',
  'PERMISSIONS_LIST',
  'PERMISSIONS_READ',
  'PERMISSIONS_UPDATE',
  'PRODUCTOS_CREATE',
  'PRODUCTOS_DELETE',
  'PRODUCTOS_LIST',
  'PRODUCTOS_READ',
  'PRODUCTOS_UPDATE',
  'PROVINCIAS_LIST',
  'PROVINCIAS_READ',
  'ROLES_CREATE',
  'ROLES_DELETE',
  'ROLES_LIST',
  'ROLES_PERMISSIONS',
  'ROLES_READ',
  'ROLES_UPDATE',
  'RUBROS_CREATE',
  'RUBROS_DELETE',
  'RUBROS_LIST',
  'RUBROS_READ',
  'RUBROS_UPDATE',
  'SECTOR_ACTIVIDADES_CREATE',
  'SECTOR_ACTIVIDADES_DELETE',
  'SECTOR_ACTIVIDADES_LIST',
  'SECTOR_ACTIVIDADES_READ',
  'SECTOR_ACTIVIDADES_UPDATE',
  'TIPOS_ENTIDAD_CREATE',
  'TIPOS_ENTIDAD_DELETE',
  'TIPOS_ENTIDAD_LIST',
  'TIPOS_ENTIDAD_PRODUCTOS_DEMANDADOS',
  'TIPOS_ENTIDAD_PRODUCTOS_OFRECIDOS',
  'TIPOS_ENTIDAD_READ',
  'TIPOS_ENTIDAD_RUBROS',
  'TIPOS_ENTIDAD_SECTOR_ACTIVIDAD',
  'TIPOS_ENTIDAD_UPDATE',
  'TIPOS_ENTIDAD_USUARIO',
  'USERS_CREATE',
  'USERS_DELETE',
  'USERS_LIST',
  'USERS_READ',
  'USERS_ROLES',
  'USERS_UPDATE',
  'USERS_UPDATE_SELF',
  'USERS_UPDATE_SELF_PASSWORD'
] as const;

type ToObj<K extends string> = {
  [P in K]: P;
};

export type PERMISSIONS_KEYS = (typeof PERMISSIONS_LIST)[number];

export type PERMISSIONS_ENUM = ToObj<PERMISSIONS_KEYS>;

export const PERMISSIONS = PERMISSIONS_LIST.reduce((acc, elem) => {
  return {
    ...acc,
    [elem]: elem
  };
}, {} as Record<PERMISSIONS_KEYS, PERMISSIONS_KEYS>);
