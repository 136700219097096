import axios from '../config/axios';

import type {
  Evento,
  PublicEventRead,
  PublicEventList,
  EventInscription,
  EventoQueryParams,
  NewEventFormValues,
  EditEventFormValues,
  PublicEventsQueryParams,
  EventInscriptionsQueryParams
} from '../interfaces/evento';
import type { PaginatedResponse } from '../interfaces/baseApiEntity';

const PATH = `eventos`;

/**
 * @description Recupera desde la API los sectores de actividad
 */
export const getEventos = async (params: EventoQueryParams) => {
  return await axios.get<PaginatedResponse<Evento>>(PATH, { params }).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera desde la API un evento por su id
 */
export const getEventoById = async (id: number) => {
  return await axios.get<Evento>(`${PATH}/${id}`).then((response) => {
    return response.data;
  });
};

/**
 * @description Crea un nuevo evento en la API
 */
export const createEvento = async (data: NewEventFormValues) => {
  return await axios.post<Evento>(PATH, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Actualiza un evento en la API
 */
export const updateEvento = async (id: number, data: EditEventFormValues) => {
  return await axios.patch<Evento>(`${PATH}/${id}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Sube una imagen para asociar a un evento
 */
export const uploadEventoImage = async (id: number, image: File) => {
  const formData = new FormData();
  formData.append('logo', image);

  return await axios.patch(`${PATH}/${id}/setLogo`, formData).then((response) => {
    return response.data;
  });
};

/**
 * @description Elimina un evento en la API
 * @param id
 */
export const deleteEvento = async (id: number) => {
  return await axios.delete(`${PATH}/${id}`).then((response) => {
    return response.data;
  });
};

/**
 * @description Obtiene los eventos públicos de la API
 */
export const getPublicEvents = async (params: PublicEventsQueryParams) => {
  return await axios
    .get<[PublicEventList[], number]>(`/public/eventos`, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Obtiene un evento público por su id
 * @param id
 */
export const getPublicEventById = async (id: number) => {
  return await axios.get<PublicEventRead>(`/public/eventos/${id}`).then((response) => {
    return response.data;
  });
};

/**
 * @description Obtiene las inscripciones a un evento
 */
export const getEventInscriptions = async (
  id: number,
  params: EventInscriptionsQueryParams = {}
) => {
  return await axios
    .get<PaginatedResponse<EventInscription>>(`/eventos/${id}/inscripciones`, { params })
    .then((response) => {
      return response.data;
    });
};

export const EventoService = {
  getEventos,
  getEventoById,
  createEvento,
  updateEvento,
  deleteEvento,
  getPublicEvents,
  uploadEventoImage,
  getPublicEventById,
  getEventInscriptions
};

export default EventoService;
