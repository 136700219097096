/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from 'react';

// Utils
import { useNavigate, useParams } from 'react-router-dom';
import { isActionableForUser } from '../../../../utils/validateRole';

// Components
import EventInscriptionContainer from './container';
import Loader from '../../../../components/layout/loader';

// Interfaces
import type { Evento } from '../../../../interfaces/evento';

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { pendingRegistrationModalActions } from '../../../../store/slices/pendingRegistrationModal';

// Services
import EventoService from '../../../../services/eventos.service';
import { InscripcionService } from '../../../../services/inscripciones.service';

// Utils
import dayjs from 'dayjs';

// Constants
import { ROUTES } from '../../../../constants/routes-constants';
import { PERMISSIONS } from '../../../../constants/permissions';

export const EventInscriptionPage = memo(() => {
  const params = useParams<{ id: string }>();
  const user = useAppSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [evento, setEvento] = useState<Evento | null>(null);

  useEffect(() => {
    setLoading(true);

    if (!user) {
      navigate(ROUTES.LOGIN_ROUTE);
      return;
    }

    if (!user.entidad) {
      navigate(ROUTES.EVENT_DETAILS_ROUTE.replace(':id', params.id!));
      dispatch(pendingRegistrationModalActions.setPendingRegistrationModal(true));
      return;
    }

    const request = !isActionableForUser(user!, [PERMISSIONS.EVENTOS_READ])
      ? EventoService.getPublicEventById
      : EventoService.getEventoById;

    request(Number(params.id))
      .then((response) => {
        const cantRegister =
          dayjs().isAfter(dayjs(response.inscripcion_fin)) ||
          dayjs().isBefore(dayjs(response.inscripcion_inicio));
        if (cantRegister) {
          navigate(ROUTES.EVENT_DETAILS_ROUTE.replace(':id', response.id.toString()));
        }

        setEvento(response as Evento);
      })
      .finally(() => setLoading(false));
  }, [params.id, user]);

  useEffect(() => {
    if (evento && user) {
      InscripcionService.isInscripted(evento.id).then((response) => {
        if (response.isInscripted) {
          navigate(ROUTES.EVENT_DETAILS_ROUTE.replace(':id', evento.id.toString()));
        }
      });
    }
  }, [evento, user]);

  if (loading) return <Loader />;

  return <EventInscriptionContainer evento={evento!} />;
});

EventInscriptionPage.displayName = 'EventInscriptionPage';

export default EventInscriptionPage;
