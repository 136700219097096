/* eslint-disable @typescript-eslint/no-unused-vars */
// Interfaces
import { CalendarOutlined, IdcardOutlined } from '@ant-design/icons';

// Constants
import { PRIMARY_COLOR } from '../../../constants/colors';
import { PERMISSIONS } from '../../../constants/permissions';
import { ROUTES } from '../../../constants/routes-constants';

// Interfaces
import type { IUser } from '../../../interfaces/user';
import type { ProLayoutProps } from '@ant-design/pro-layout';

const getEventsRoutes = (_isMobile: boolean, permissions: string[]) => {
  const routes: NonNullable<ProLayoutProps['route']>[] = [];

  if (permissions.includes(PERMISSIONS.EVENTOS_CREATE)) {
    routes.push({
      exact: true,
      key: 'new-event',
      name: 'Nuevo evento',
      path: ROUTES.NEW_EVENT_ROUTE
    });
  }

  if (permissions.includes(PERMISSIONS.EVENTOS_LIST)) {
    routes.push({
      exact: true,
      key: 'events-list',
      name: 'Listado de eventos',
      path: ROUTES.EVENTS_LIST_ROUTE
    });
  }

  if (routes.length === 1) {
    const route = routes[0];
    route.icon = <CalendarOutlined style={{ color: PRIMARY_COLOR }} />;
    return route;
  }

  return {
    key: 'events',
    name: 'Eventos',
    children: routes,
    icon: <CalendarOutlined style={{ color: PRIMARY_COLOR }} />
  };
};

const getEntitiesRoutes = (_isMobile: boolean, permissions: string[]) => {
  const routes: NonNullable<ProLayoutProps['route']>[] = [];

  if (permissions.includes(PERMISSIONS.ENTIDADES_CREATE)) {
    routes.push({
      exact: true,
      key: 'new-entity',
      name: 'Nueva empresa',
      path: ROUTES.ADMIN_NEW_ENTITY_ROUTE
    });
  }

  if (permissions.includes(PERMISSIONS.ENTIDADES_LIST)) {
    routes.push({
      exact: true,
      key: 'entities-list',
      name: 'Listado de empresas',
      path: ROUTES.ENTITIES_LIST_ROUTE
    });
  }

  if (routes.length === 1) {
    const route = routes[0];
    route.icon = <IdcardOutlined style={{ color: PRIMARY_COLOR }} />;
    return route;
  }

  return {
    key: 'entities',
    name: 'Empresas',
    children: routes,
    icon: <IdcardOutlined style={{ color: PRIMARY_COLOR }} />
  };
};

// const getComunicationsRoutes = (_isMobile: boolean, permissions: string[]) => {
//   const routes: NonNullable<ProLayoutProps['route']>[] = [];

//   routes.push({
//     exact: true,
//     key: 'new-comunication',
//     name: 'Nueva comunicación',
//     path: ROUTES.COMUNICATIONS_NEW_ROUTE
//   });

//   routes.push({
//     exact: true,
//     key: 'templates',
//     name: 'Listado de plantillas',
//     path: ROUTES.COMUNICATIONS_TEMPLATES_ROUTE
//   });

//   routes.push({
//     exact: true,
//     key: 'new-comunication',
//     name: 'Listado de comunicaciones',
//     path: ROUTES.COMUNICATIONS_LIST_ROUTE
//   });

//   if (routes.length === 1) {
//     const route = routes[0];
//     route.icon = <MailOutlined style={{ color: PRIMARY_COLOR }} />;
//     return route;
//   }

//   return {
//     children: routes,
//     key: 'comunications',
//     name: 'Comunicaciones',
//     icon: <MailOutlined style={{ color: PRIMARY_COLOR }} />
//   };
// };

export default (_isMobile: boolean, _user: IUser) => {
  const routes: ProLayoutProps['route'][] = [];

  const permissions =
    (_user && _user?.roles.map((r) => r.permissions?.map((p) => p.descripcion)).flat()) || [];

  // Eventos
  const eventsRoutes = getEventsRoutes(_isMobile, permissions);
  if (eventsRoutes) routes.push(eventsRoutes);

  // Entidades
  const entitiesRoutes = getEntitiesRoutes(_isMobile, permissions);
  if (entitiesRoutes) routes.push(entitiesRoutes);

  // Comunicaciones
  // const comunicationsRoutes = getComunicationsRoutes(_isMobile, permissions);
  // if (comunicationsRoutes) routes.push(comunicationsRoutes);

  return {
    routes
  };
};
